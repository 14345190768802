.Home {
  min-height: 100vh;
  display: flex;
}

.WelcomeCard {
  margin: auto;
  padding: 2em;
  position: relative;
  border: solid 2px black;
  background: var(--opaque-background);
  backdrop-filter: blur(2px);
  box-shadow: var(--shadow-value);
}

.welcome-card-inner {
  background: white;
  padding: 5em;
}

.welcome-text {
  text-transform: uppercase;
  margin: 10px;
  font-size: 24px;
}

.welcome-text:first-of-type {
  font-size: 32px;
}

.welcome-text > p {
  display: inline;
}

.my-name {
  display: inline;
  font-family: var(--header-font);
  font-weight: 500;
  font-size: 36px;
  text-shadow: 2px 2px lightgrey;
  padding: 0.5em;
}

.my-name::before {
  content: 'danny irwin';
  position: absolute;
  transform: translate(-2px, -2px);
  color: var(--color4);
  text-shadow: none;
}

.welcome-nav-container {
  display: flex;
  flex-direction: row;
}

.welcome-nav-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.welcome-nav-btn:hover,
.welcome-nav-btn:focus {
  color: grey;
}

.welcome-nav-btn > * {
  display: inline;
  font-size: 20px;
}

.welcome-nav-btn:hover > *:not(p),
.welcome-nav-btn:focus > *:not(p) {
  animation: spin 0.2s;
  transform: rotate(90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 750px) {
  .WelcomeCard {
    margin: auto;
    max-width: 70vw;
    padding: 1em;
  }

  .welcome-card-inner {
    padding: 1em;
  }

  .welcome-text {
    text-transform: uppercase;
    font-family: var(--header-font);
    font-size: 16px;
    padding: 0px;
  }

  .welcome-text:first-of-type {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .welcome-text > p {
    padding: 0px;
    margin: 0px;
  }

  .my-name {
    font-size: 20px;
    padding: 0;
  }
  w .welcome-nav-btn > * {
    font-size: 14px;
  }
}
