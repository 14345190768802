.Header {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: var(--color2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-btn {
  position: relative;
  font-family: var(--header-font);
  font-weight: 500;
  font-size: 20px;
  color: black;
  cursor: pointer;
  margin: 0px;
  padding: 15px 30px;
}

.nav-btn:hover,
.nav-btn:focus,
.SocialMediaLinks > *:hover,
.SocialMediaLinks > *:focus {
  color: lightgrey;
}

.nav-btn:hover::before,
.nav-btn:focus::before {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
  /* box-shadow: 1px 1px white; */
  background-color: lightgrey;
  width: 35%;
}

.nav-btn::before {
  position: absolute;
  content: ' ';
  width: 10%;
  height: 2px;
  background: black;
  transition: expandBar 1s reverse ease;
  z-index: -1;
}

.selected.nav-btn::before {
  -webkit-transition: width 0.5s ease;
  -moz-transition: width 0.5s ease;
  -o-transition: width 0.5s ease;
  transition: width 0.5s ease;
  background-color: white;

  width: 60%;
}

.nav-btn.selected {
  color: white;
}

.Resume {
  transform: translateY(-100vh);
  width: 100%;
  max-width: 1024px;
  margin: 2em;
  transform: 0.5s;
  border: 2px solid black;
  background: white;
}

.Resume.visible {
  transform: translate(0);
  transform: 0.5s;
}

@media only screen and (max-width: 850px) {
  .Header > .SocialMediaLinks {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .nav-btn {
    padding: 15px;
  }

  .nav-btn::before {
    display: none;
  }

  .nav-btn.selected {
    border-bottom: 4px solid white;
  }
}
