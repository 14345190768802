.Contact {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ContactHeader {
  background-color: white;
  position: relative;
  padding: 1em;
  border: solid 2px black;
  margin: auto;
}

.ContactHeader > button {
  font-size: 20px;
  color: white;
  background: var(--color5);
  padding: 0 3em;
  cursor: pointer;
}

.ContactHeader > button:hover,
.ContactHeader > button:focus {
  color: black;
}

.ContactForm {
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 2em;
  margin: auto;
  transform: translateY(100vh);
  transition: 0.5s ease;
}

.ContactForm.visible {
  transform: translateY(0%);
  transition: 0.5s ease;
}

.ContactHeader > button > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-back-btn {
  border: 2px solid black;
  font-size: 20px;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-back-btn:hover {
  color: var(--color5);
  border-color: var(--color5);
}

input[type='submit'] {
  background-color: var(--color5);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
  color: white;
}
input[type='submit']:hover,
input[type='submit']:focus {
  color: black;
}

.input-container {
  border-bottom: black solid 1px;
  text-align: left;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevron-panel {
  position: absolute;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  height: 230px;
  width: 100%;
  top: 0;
}
.chevron-panel:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 200%;
  width: 50%;
  background: white;
  transform: skew(0deg, 15deg) translateY(-30%);
}
.chevron-panel:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 200%;
  width: 50%;
  background: white;
  transform: skew(0deg, -15deg) translateY(-30%);
}

.back::before {
  transform: skew(0deg, 15deg) translateY(-20%);
  background: var(--opaque-background);
  backdrop-filter: blur(2px);
  border-bottom: solid 2px black;
}
.back::after {
  transform: skew(0deg, -15deg) translateY(-20%);
  background: var(--opaque-background);
  backdrop-filter: blur(2px);
  border-bottom: solid 2px black;
}

.message-input-container {
  flex-direction: column;
}

.ContactStatusCard {
  background: white;
  margin: auto;
  width: 300px;
  padding: 1em;
}
.ContactStatusCard > button {
  margin-top: 1rem;
}

@media only screen and (max-width: 700px) {
  .ContactHeader > button {
    padding: 1em;
  }

  textarea {
    width: 100%;
  }

  .ContactHeader {
    margin: 1em;
  }

  .contact-form-container {
    padding: 1em;
  }
}
