.About {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-me-outer-container {
  background-color: var(--opaque-background);
  backdrop-filter: blur(2px);
  padding-left: 4em;
  border-bottom: 2px solid black;
  height: 100vh;
  flex-basis: 2;
  width: 66.66%;
}

.about-me-container {
  width: 100%;
  background: white;
}

.about-me-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70ch;
  padding: 2em;
  background: white;
  height: 100vh;
}

.AboutMeText {
  text-align: start;
}

.AboutMeText > p {
  margin-top: 1rem;
}

.AboutMeTechs {
  margin: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 500px;
}

.TechCard {
  margin: 0.5em;
}

.tech-logo {
  height: 48px;
  width: 48px;
}

.about-me-image-panel {
  background: white;
  width: 33.33%;
  display: flex;
}

.AboutMeImage {
  height: 246px;
  width: 246px;
  object-fit: contain;
  margin: auto;
  border: solid var(--color2) 8px;
  border-radius: 50%;
  flex-basis: 1;
}

.image-container {
  padding: 2em;
  border: solid 2px black;
  background-color: var(--opaque-background);
  backdrop-filter: blur(2px);
  border-radius: 50%;
  box-shadow: var(--shadow-value);
  margin: auto;
  margin-right: 2em;
}

@media only screen and (max-width: 1024px) {
  .About {
    flex-direction: column;
  }

  .AboutMeImage {
    height: 150px;
    width: 150px;
  }

  .about-me-image-panel {
    background: white;
    width: 100%;
    padding: 1em 0;
  }
  .image-container {
    margin: auto;
  }

  .about-me-outer-container {
    padding-left: 0px;
    padding-top: 2em;
    width: 100%;
    height: 100%;
    border: none;
  }

  .about-me-container {
    margin: auto;
  }

  .about-me-content {
    margin: auto;
    height: 100%;
  }

  .about-me-container {
    height: auto;
  }

  .tech-logo {
    height: 32px;
    width: 32px;
  }
}
