.Portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0px;
  max-width: 100vw;
  padding: 3em 0;

  --preview-image-height: 200px;
  --preview-image-width: 300px;
  --detail-image-size: 500px;
  --width: 1024px;
  --color: var(--color3);
}

.portfolio-container-outer {
  margin: auto;
  padding: 2em;
  background: var(--opaque-background);
  backdrop-filter: blur(2px);
  border: solid black 2px;
  box-shadow: var(--shadow-value);
}

.portfolio-container {
  background: white;
  max-width: var(--width);
}

.portfolio-header {
  background: white;
  padding: 1.5em;
  margin: 0;
}

.portfolio-content {
  position: relative;
  transition: 0.5s;
  display: flex;
  height: 100%;
  flex-direction: row;
  max-width: var(--width);
  overflow-x: hidden;
}

.ProjectsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-bottom: 3em;

  transform: translateX(-100vw);
  transition: 0.5s;
}

.ProjectDetails {
  position: absolute;
  width: var(--width);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  transform: translateX(100vw);
  transition: 0.5s;
  margin-top: auto;
}

.ProjectDetails.visible,
.ProjectsContainer.visible {
  transform: translateX(0vw);
  transition: 0.5s;
}

.ProjectDetailsImages {
  max-height: var(--detail-image-size);
  max-width: var(--detail-image-size);
  background: black;
}

.ProjectDetailsImages > img {
  max-height: var(--detail-image-size);
  max-width: var(--detail-image-size);
}

.ProjectDetailsText {
  max-width: 50ch;
  margin: 2em;
  border-top: solid 2px;
}

.ProjectCard {
  position: relative;
  width: var(--preview-image-width);
  padding-bottom: 0.5em;
  box-shadow: var(--shadow-value);
  background-color: white;
  margin: 0.5em;
  color: white;
}

.ProjectCard > p {
  margin: 5px;
}

.project-title {
  display: inline;
  font-family: var(--header-font);
  font-size: 20px;
  color: white;
  padding: 0.5em;
  font-weight: 500;
}

.this-project-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.this-project-info-container > svg {
  margin-right: 5px;
  fill: grey;
}
.this-project-info-container > p > a {
  margin-right: 5px;
  color: grey;
}

.ProjectCard > img {
  width: var(--preview-image-width);
  height: var(--preview-image-height);
  object-fit: cover;
}

.ProjectTechs {
  font-size: 12px;
}

.see-more-container {
  position: absolute;
  display: flex;
  top: 0;
  width: var(--preview-image-width);
  height: var(--preview-image-height);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.see-more-container:hover,
.see-more-container:focus-within {
  opacity: 1;
}

.see-more-btn {
  color: black;
  font-size: 20px;
  padding: 1em;
  background-color: white;
  cursor: pointer;
}

.see-more-btn-container {
  background-color: var(--opaque-background);
  margin: auto;
  padding: 1em;
  backdrop-filter: blur(2px);
  border: 2px solid;
  cursor: pointer;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
}

.BackToGalleryBtn {
  margin: 0px;
  padding: 0px;
  min-width: 32px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

.BackToGalleryBtn:hover,
.BackToGalleryBtn:focus {
  color: black;
}

.BackToGalleryBtn > * {
  text-orientation: sideways-right;
  writing-mode: vertical-rl;
  font-weight: 500;
  font-size: 16px;
}

.project-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.ProjectLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  color: black;
}

.ProjectLink > * {
  align-content: center;
  margin: 0 0.5em;
}

.slideshow-controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: var(--detail-image-size);
  flex-direction: row;
  justify-content: space-between;
  height: var(--detail-image-size);
}

.slideshow-controls > button {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1em;
  cursor: pointer;
  color: black;
  background: rgba(255, 255, 255, 0.2);
  margin: auto 0;
  border: solid 2px var(--opaque-background);
}

.slideshow-controls > button:hover,
.slideshow-controls > button:focus {
  background: rgba(255, 255, 255, 0.5);
  border: solid 2px var(grey);
}

.hidden {
  display: none;
}

.visible {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1030px) {
  .Portfolio {
    --width: 100%;
    --detail-image-size: 40vw;
  }
  .portfolio-container-outer {
    margin: 1em;
  }

  .ProjectDetailsText {
    font-size: 14px;
    margin: 10px;
    overflow-x: auto;
  }

  .ProjectDetails {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .BackToGalleryBtn {
    top: 0;
    height: 32px;
    flex-direction: row;
    width: 100%;
  }

  .BackToGalleryBtn > * {
    text-orientation: upright;
    writing-mode: horizontal-tb;
  }

  .Portfolio {
    --width: 100%;
    --detail-image-size: 500px;
  }

  .click-to-view-details {
    visibility: visible;
  }
}

@media only screen and (max-width: 750px) {
  .Portfolio {
    --preview-image-height: 100px;
    --preview-image-width: 130px;
    --detail-image-size: 300px;
    margin: 2px;
  }

  .project-title {
    font-size: 16px;
  }

  .ProjectCard > p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .portfolio-container-outer {
    margin: 0;
    padding: 10px;
    border-left: none;
    border-right: none;
  }
}
