@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --header-font: 'Raleway', sans-serif;

  /* --color1: #1be7ff;
  --color2: #6eeb83;
  --color3: #e4ff1a;
  --color4: #ffb800;
  --color5: #ff5714; */

  --color1: #003d5b;
  --color2: #30638e;
  --color3: #00798c;
  --color4: #d1495b;
  --color5: #edae49;

  --shadow-value: 10px 10px 15px rgba(0, 0, 0, 0.2);
  --opaque-background: rgba(0, 0, 0, 0.05);
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: 'Raleway', sans-serif;
  font-family: var(--header-font);
  display: flex;
  flex-direction: column;
}

.P5Background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

section {
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-y: hidden;
}

button {
  border: none;
  background: none;
  padding: 5px 30px;
  margin: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

hr {
  border-style: none;
  height: 1px;
  background: black;
  width: 50%;
}

h1 {
  text-transform: uppercase;
  font-family: 'DM Serif Display', serif;
}

h2,
h3 {
  font-weight: 500;
  text-transform: uppercase;
}

h3 {
  margin: 5px 0;
}

p {
  margin: 5px 0;
}

.DotsContainer {
  display: flex;
  flex-direction: row;
  margin: 0.5em;
}

.Dot {
  height: 0.75em;
  width: 0.75em;
  margin: 0.5em;
  border-radius: 50%;
}

input {
  border: none;
  float: right;
  width: 100%;
  padding: 0.5em;
  font-size: 16px;
  font-family: 'Raleway', sans-serif;
  font-family: var(--header-font);
}

textarea {
  height: 150px;
  width: 500px;
  font-family: 'Raleway', sans-serif;
  font-family: var(--header-font);
  padding: 0.5em;
}

label {
  margin-right: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.SocialMediaLinks {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.SocialMediaLinks > * > * {
  width: 26px;
  height: 26px;
  margin: auto;
}

.SocialMediaLinks > * {
  color: black;
  margin: auto;
  padding: 3px;
}

.background-disable-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  margin: auto;
  display: flex;
  width: 100%;
  z-index: -20;
  opacity: 0;
  bottom: 0;
  transform: translateX(100vw);
  transition: 0.5s;
}

.background-disable-container.visible {
  z-index: 20;
  opacity: 1;
  transform: translateX(0vw);
  transition: 0.5s;
  padding: no;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  button {
    padding: 5px 5px;
  }
  section {
    padding-top: 50px;
  }

  section:first-of-type {
    padding-top: 0;
  }
}

.Home {
  min-height: 100vh;
  display: flex;
}

.WelcomeCard {
  margin: auto;
  padding: 2em;
  position: relative;
  border: solid 2px black;
  background: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  box-shadow: var(--shadow-value);
}

.welcome-card-inner {
  background: white;
  padding: 5em;
}

.welcome-text {
  text-transform: uppercase;
  margin: 10px;
  font-size: 24px;
}

.welcome-text:first-of-type {
  font-size: 32px;
}

.welcome-text > p {
  display: inline;
}

.my-name {
  display: inline;
  font-family: var(--header-font);
  font-weight: 500;
  font-size: 36px;
  text-shadow: 2px 2px lightgrey;
  padding: 0.5em;
}

.my-name::before {
  content: 'danny irwin';
  position: absolute;
  transform: translate(-2px, -2px);
  color: var(--color4);
  text-shadow: none;
}

.welcome-nav-container {
  display: flex;
  flex-direction: row;
}

.welcome-nav-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  cursor: pointer;
}

.welcome-nav-btn:hover,
.welcome-nav-btn:focus {
  color: grey;
}

.welcome-nav-btn > * {
  display: inline;
  font-size: 20px;
}

.welcome-nav-btn:hover > *:not(p),
.welcome-nav-btn:focus > *:not(p) {
  animation: spin 0.2s;
  transform: rotate(90deg);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}

@media only screen and (max-width: 750px) {
  .WelcomeCard {
    margin: auto;
    max-width: 70vw;
    padding: 1em;
  }

  .welcome-card-inner {
    padding: 1em;
  }

  .welcome-text {
    text-transform: uppercase;
    font-family: var(--header-font);
    font-size: 16px;
    padding: 0px;
  }

  .welcome-text:first-of-type {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }

  .welcome-text > p {
    padding: 0px;
    margin: 0px;
  }

  .my-name {
    font-size: 20px;
    padding: 0;
  }
  w .welcome-nav-btn > * {
    font-size: 14px;
  }
}

.About {
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-me-outer-container {
  background-color: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  padding-left: 4em;
  border-bottom: 2px solid black;
  height: 100vh;
  flex-basis: 2;
  width: 66.66%;
}

.about-me-container {
  width: 100%;
  background: white;
}

.about-me-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 70ch;
  padding: 2em;
  background: white;
  height: 100vh;
}

.AboutMeText {
  text-align: start;
}

.AboutMeText > p {
  margin-top: 1rem;
}

.AboutMeTechs {
  margin: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 500px;
}

.TechCard {
  margin: 0.5em;
}

.tech-logo {
  height: 48px;
  width: 48px;
}

.about-me-image-panel {
  background: white;
  width: 33.33%;
  display: flex;
}

.AboutMeImage {
  height: 246px;
  width: 246px;
  object-fit: contain;
  margin: auto;
  border: solid var(--color2) 8px;
  border-radius: 50%;
  flex-basis: 1;
}

.image-container {
  padding: 2em;
  border: solid 2px black;
  background-color: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-radius: 50%;
  box-shadow: var(--shadow-value);
  margin: auto;
  margin-right: 2em;
}

@media only screen and (max-width: 1024px) {
  .About {
    flex-direction: column;
  }

  .AboutMeImage {
    height: 150px;
    width: 150px;
  }

  .about-me-image-panel {
    background: white;
    width: 100%;
    padding: 1em 0;
  }
  .image-container {
    margin: auto;
  }

  .about-me-outer-container {
    padding-left: 0px;
    padding-top: 2em;
    width: 100%;
    height: 100%;
    border: none;
  }

  .about-me-container {
    margin: auto;
  }

  .about-me-content {
    margin: auto;
    height: 100%;
  }

  .about-me-container {
    height: auto;
  }

  .tech-logo {
    height: 32px;
    width: 32px;
  }
}

.Portfolio {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em 0px;
  max-width: 100vw;
  padding: 3em 0;

  --preview-image-height: 200px;
  --preview-image-width: 300px;
  --detail-image-size: 500px;
  --width: 1024px;
  --color: var(--color3);
}

.portfolio-container-outer {
  margin: auto;
  padding: 2em;
  background: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border: solid black 2px;
  box-shadow: var(--shadow-value);
}

.portfolio-container {
  background: white;
  max-width: var(--width);
}

.portfolio-header {
  background: white;
  padding: 1.5em;
  margin: 0;
}

.portfolio-content {
  position: relative;
  transition: 0.5s;
  display: flex;
  height: 100%;
  flex-direction: row;
  max-width: var(--width);
  overflow-x: hidden;
}

.ProjectsContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-bottom: 3em;

  transform: translateX(-100vw);
  transition: 0.5s;
}

.ProjectDetails {
  position: absolute;
  width: var(--width);
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  transform: translateX(100vw);
  transition: 0.5s;
  margin-top: auto;
}

.ProjectDetails.visible,
.ProjectsContainer.visible {
  transform: translateX(0vw);
  transition: 0.5s;
}

.ProjectDetailsImages {
  max-height: var(--detail-image-size);
  max-width: var(--detail-image-size);
  background: black;
}

.ProjectDetailsImages > img {
  max-height: var(--detail-image-size);
  max-width: var(--detail-image-size);
}

.ProjectDetailsText {
  max-width: 50ch;
  margin: 2em;
  border-top: solid 2px;
}

.ProjectCard {
  position: relative;
  width: var(--preview-image-width);
  padding-bottom: 0.5em;
  box-shadow: var(--shadow-value);
  background-color: white;
  margin: 0.5em;
  color: white;
}

.ProjectCard > p {
  margin: 5px;
}

.project-title {
  display: inline;
  font-family: var(--header-font);
  font-size: 20px;
  color: white;
  padding: 0.5em;
  font-weight: 500;
}

.this-project-info-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.this-project-info-container > svg {
  margin-right: 5px;
  fill: grey;
}
.this-project-info-container > p > a {
  margin-right: 5px;
  color: grey;
}

.ProjectCard > img {
  width: var(--preview-image-width);
  height: var(--preview-image-height);
  object-fit: cover;
}

.ProjectTechs {
  font-size: 12px;
}

.see-more-container {
  position: absolute;
  display: flex;
  top: 0;
  width: var(--preview-image-width);
  height: var(--preview-image-height);
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.see-more-container:hover,
.see-more-container:focus-within {
  opacity: 1;
}

.see-more-btn {
  color: black;
  font-size: 20px;
  padding: 1em;
  background-color: white;
  cursor: pointer;
}

.see-more-btn-container {
  background-color: var(--opaque-background);
  margin: auto;
  padding: 1em;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border: 2px solid;
  cursor: pointer;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.5);
}

.BackToGalleryBtn {
  margin: 0px;
  padding: 0px;
  min-width: 32px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: white;
}

.BackToGalleryBtn:hover,
.BackToGalleryBtn:focus {
  color: black;
}

.BackToGalleryBtn > * {
  -webkit-text-orientation: sideways-right;
          text-orientation: sideways-right;
  -ms-writing-mode: tb-rl;
      writing-mode: vertical-rl;
  font-weight: 500;
  font-size: 16px;
}

.project-links-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

.ProjectLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5em;
  color: black;
}

.ProjectLink > * {
  align-content: center;
  margin: 0 0.5em;
}

.slideshow-controls {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: var(--detail-image-size);
  flex-direction: row;
  justify-content: space-between;
  height: var(--detail-image-size);
}

.slideshow-controls > button {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 1em;
  cursor: pointer;
  color: black;
  background: rgba(255, 255, 255, 0.2);
  margin: auto 0;
  border: solid 2px var(--opaque-background);
}

.slideshow-controls > button:hover,
.slideshow-controls > button:focus {
  background: rgba(255, 255, 255, 0.5);
  border: solid 2px var(grey);
}

.hidden {
  display: none;
}

.visible {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1030px) {
  .Portfolio {
    --width: 100%;
    --detail-image-size: 40vw;
  }
  .portfolio-container-outer {
    margin: 1em;
  }

  .ProjectDetailsText {
    font-size: 14px;
    margin: 10px;
    overflow-x: auto;
  }

  .ProjectDetails {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .BackToGalleryBtn {
    top: 0;
    height: 32px;
    flex-direction: row;
    width: 100%;
  }

  .BackToGalleryBtn > * {
    -webkit-text-orientation: upright;
            text-orientation: upright;
    -ms-writing-mode: lr-tb;
        writing-mode: horizontal-tb;
  }

  .Portfolio {
    --width: 100%;
    --detail-image-size: 500px;
  }

  .click-to-view-details {
    visibility: visible;
  }
}

@media only screen and (max-width: 750px) {
  .Portfolio {
    --preview-image-height: 100px;
    --preview-image-width: 130px;
    --detail-image-size: 300px;
    margin: 2px;
  }

  .project-title {
    font-size: 16px;
  }

  .ProjectCard > p {
    font-size: 12px;
  }
}

@media only screen and (max-width: 450px) {
  .portfolio-container-outer {
    margin: 0;
    padding: 10px;
    border-left: none;
    border-right: none;
  }
}

.Blog {
  height: 100%;
  width: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BlogCard {
  text-align: left;
}

.BlogCard > a {
  text-decoration: none;
  color: black;

  border: 2px solid black;
  margin: 0.5em;
  padding: 1em;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.blog-card-content {
  max-width: 50ch;
  margin-right: 0.5em;
}

.blog-card-source {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: grey;
}

@media only screen and (max-width: 750px) {
  .BlogCard {
    font-size: 12px;
  }
}

@media only screen and (max-width: 400px) {
  .BlogCard > a {
    flex-direction: column;
  }
}

.Contact {
  position: relative;
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.ContactHeader {
  background-color: white;
  position: relative;
  padding: 1em;
  border: solid 2px black;
  margin: auto;
}

.ContactHeader > button {
  font-size: 20px;
  color: white;
  background: var(--color5);
  padding: 0 3em;
  cursor: pointer;
}

.ContactHeader > button:hover,
.ContactHeader > button:focus {
  color: black;
}

.ContactForm {
  position: relative;
  background: white;
  display: flex;
  flex-direction: column;
  padding: 2em;
  margin: auto;
  transform: translateY(100vh);
  transition: 0.5s ease;
}

.ContactForm.visible {
  transform: translateY(0%);
  transition: 0.5s ease;
}

.ContactHeader > button > h2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-back-btn {
  border: 2px solid black;
  font-size: 20px;
  width: 100%;
  margin: 0;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.go-back-btn:hover {
  color: var(--color5);
  border-color: var(--color5);
}

input[type='submit'] {
  background-color: var(--color5);
  font-weight: 500;
  text-transform: uppercase;
  font-size: 20px;
  color: white;
}
input[type='submit']:hover,
input[type='submit']:focus {
  color: black;
}

.input-container {
  border-bottom: black solid 1px;
  text-align: left;
  padding: 0.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.chevron-panel {
  position: absolute;
  text-align: center;
  padding: 12px;
  margin-bottom: 6px;
  height: 230px;
  width: 100%;
  top: 0;
}
.chevron-panel:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 200%;
  width: 50%;
  background: white;
  transform: skew(0deg, 15deg) translateY(-30%);
}
.chevron-panel:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 200%;
  width: 50%;
  background: white;
  transform: skew(0deg, -15deg) translateY(-30%);
}

.back::before {
  transform: skew(0deg, 15deg) translateY(-20%);
  background: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-bottom: solid 2px black;
}
.back::after {
  transform: skew(0deg, -15deg) translateY(-20%);
  background: var(--opaque-background);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  border-bottom: solid 2px black;
}

.message-input-container {
  flex-direction: column;
}

.ContactStatusCard {
  background: white;
  margin: auto;
  width: 300px;
  padding: 1em;
}
.ContactStatusCard > button {
  margin-top: 1rem;
}

@media only screen and (max-width: 700px) {
  .ContactHeader > button {
    padding: 1em;
  }

  textarea {
    width: 100%;
  }

  .ContactHeader {
    margin: 1em;
  }

  .contact-form-container {
    padding: 1em;
  }
}

.Header {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background: var(--color2);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.nav-btn {
  position: relative;
  font-family: var(--header-font);
  font-weight: 500;
  font-size: 20px;
  color: black;
  cursor: pointer;
  margin: 0px;
  padding: 15px 30px;
}

.nav-btn:hover,
.nav-btn:focus,
.SocialMediaLinks > *:hover,
.SocialMediaLinks > *:focus {
  color: lightgrey;
}

.nav-btn:hover::before,
.nav-btn:focus::before {
  transition: width 0.5s ease;
  /* box-shadow: 1px 1px white; */
  background-color: lightgrey;
  width: 35%;
}

.nav-btn::before {
  position: absolute;
  content: ' ';
  width: 10%;
  height: 2px;
  background: black;
  transition: expandBar 1s reverse ease;
  z-index: -1;
}

.selected.nav-btn::before {
  transition: width 0.5s ease;
  background-color: white;

  width: 60%;
}

.nav-btn.selected {
  color: white;
}

.Resume {
  transform: translateY(-100vh);
  width: 100%;
  max-width: 1024px;
  margin: 2em;
  transform: 0.5s;
  border: 2px solid black;
  background: white;
}

.Resume.visible {
  transform: translate(0);
  transform: 0.5s;
}

@media only screen and (max-width: 850px) {
  .Header > .SocialMediaLinks {
    display: none;
  }
}

@media only screen and (max-width: 700px) {
  .nav-btn {
    padding: 15px;
  }

  .nav-btn::before {
    display: none;
  }

  .nav-btn.selected {
    border-bottom: 4px solid white;
  }
}

