@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;900&display=swap');

:root {
  --header-font: 'Raleway', sans-serif;

  /* --color1: #1be7ff;
  --color2: #6eeb83;
  --color3: #e4ff1a;
  --color4: #ffb800;
  --color5: #ff5714; */

  --color1: #003d5b;
  --color2: #30638e;
  --color3: #00798c;
  --color4: #d1495b;
  --color5: #edae49;

  --shadow-value: 10px 10px 15px rgba(0, 0, 0, 0.2);
  --opaque-background: rgba(0, 0, 0, 0.05);
}

* {
  box-sizing: border-box;
}

.App {
  text-align: center;
  font-family: var(--header-font);
  display: flex;
  flex-direction: column;
}

.P5Background {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

section {
  min-height: 100vh;
  max-width: 100vw;
  position: relative;
  overflow-y: hidden;
}

button {
  border: none;
  background: none;
  padding: 5px 30px;
  margin: 10px;
  text-transform: uppercase;
  cursor: pointer;
}

hr {
  border-style: none;
  height: 1px;
  background: black;
  width: 50%;
}

h1 {
  text-transform: uppercase;
  font-family: 'DM Serif Display', serif;
}

h2,
h3 {
  font-weight: 500;
  text-transform: uppercase;
}

h3 {
  margin: 5px 0;
}

p {
  margin: 5px 0;
}

.DotsContainer {
  display: flex;
  flex-direction: row;
  margin: 0.5em;
}

.Dot {
  height: 0.75em;
  width: 0.75em;
  margin: 0.5em;
  border-radius: 50%;
}

input {
  border: none;
  float: right;
  width: 100%;
  padding: 0.5em;
  font-size: 16px;
  font-family: var(--header-font);
}

textarea {
  height: 150px;
  width: 500px;
  font-family: var(--header-font);
  padding: 0.5em;
}

label {
  margin-right: 0.5em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
}

.SocialMediaLinks {
  display: flex;
  flex-direction: row;
  align-content: center;
}

.SocialMediaLinks > * > * {
  width: 26px;
  height: 26px;
  margin: auto;
}

.SocialMediaLinks > * {
  color: black;
  margin: auto;
  padding: 3px;
}

.background-disable-container {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(2px);
  margin: auto;
  display: flex;
  width: 100%;
  z-index: -20;
  opacity: 0;
  bottom: 0;
  transform: translateX(100vw);
  transition: 0.5s;
}

.background-disable-container.visible {
  z-index: 20;
  opacity: 1;
  transform: translateX(0vw);
  transition: 0.5s;
  padding: no;
  height: 100vh;
}

@media only screen and (max-width: 1024px) {
  button {
    padding: 5px 5px;
  }
  section {
    padding-top: 50px;
  }

  section:first-of-type {
    padding-top: 0;
  }
}
